/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content, jsx-a11y/heading-has-content */
import { Trans as I18nTrans, useTranslation } from "react-i18next";

export const Trans = ({ components = {}, ...props }) => {
  const comps = {
    p: <p />,
    b: <strong />,
    strong: <strong />,
    em: <em />,
    ul: <ul />,
    ol: <ol />,
    li: <li />,
    sup: <sup />,
    sub: <sub />,
    span: <span />,
    h1: <h1 />,
    h2: <h2 />,
    h3: <h3 />,
    h4: <h4 />,
    h5: <h5 />,
    h6: <h6 />,
    a: <a />,
    br: <br />,
    ...components,
  };

  return <I18nTrans components={comps} {...props} />;
};

const isNumeric = (value) =>
  !isNaN(Number.parseFloat(value)) && isFinite(value);

export const formatI18nCurrency = ({
  value,
  currency = "USD",
  language,
  maximumFractionDigits = 2,
  minimumFractionDigits = 0,
  compactNotation = false,
  trailingZeroDisplay = "auto",
}) => {
  if (!isNumeric(value))
    throw new Error("formatI18nCurrency: value must be a valid number.");

  const intlValue = new Intl.NumberFormat(language, {
    style: "currency",
    notation: compactNotation ? "compact" : "standard",
    currency,
    maximumFractionDigits,
    minimumFractionDigits,
    trailingZeroDisplay,
  }).format(value);

  return intlValue;
};

export const formatI18nNumber = ({
  value,
  language,
  maximumFractionDigits = 2,
  minimumFractionDigits = 0,
  compactNotation = false,
  trailingZeroDisplay = "auto",
}) => {
  if (!isNumeric(value))
    throw new Error("formatI18nNumber: value must be a valid number.");

  const intlValue = new Intl.NumberFormat(language, {
    maximumFractionDigits,
    minimumFractionDigits,
    notation: compactNotation ? "compact" : "standard",
    trailingZeroDisplay,
  }).format(value);

  return intlValue;
};

export const useI18nNumber = ({
  value,
  maximumFractionDigits = 0,
  minimumFractionDigits = 0,
  trailingZeroDisplay = "auto",
}) => {
  const { i18n } = useTranslation();

  return formatI18nNumber({
    value,
    language: i18n.language,
    maximumFractionDigits,
    minimumFractionDigits,
    trailingZeroDisplay,
  });
};

export const useI18nNumberRange = ({
  minValue,
  maxValue,
  maximumFractionDigits = 0,
}) => {
  const { i18n } = useTranslation();
  const numberConfig = {
    language: i18n.language,
    maximumFractionDigits,
    compactNotation: true,
  };

  const min = formatI18nNumber({ value: minValue || 0, ...numberConfig });
  const max = formatI18nNumber({ value: maxValue || 0, ...numberConfig });

  return minValue || maxValue ? (min === max ? min : `${min} - ${max}`) : "0";
};

export const useI18nCurrency = ({
  value,
  currency = "USD",
  maximumFractionDigits = 2,
  compactNotation = false,
  trailingZeroDisplay = "auto",
}) => {
  const { i18n } = useTranslation();

  return formatI18nCurrency({
    value,
    currency,
    language: i18n.language,
    maximumFractionDigits,
    compactNotation,
    trailingZeroDisplay,
  });
};

export const useI18nCurrencyRange = ({
  minValue,
  maxValue,
  currency = "USD",
  maximumFractionDigits = 0,
}) => {
  const { i18n } = useTranslation();
  const currencyConfig = {
    currency,
    language: i18n.language,
    maximumFractionDigits,
    compactNotation: true,
  };

  const min = formatI18nCurrency({ value: minValue || 0, ...currencyConfig });
  const max = formatI18nCurrency({ value: maxValue || 0, ...currencyConfig });

  return minValue || maxValue ? (min === max ? min : `${min} - ${max}`) : "0";
};

export { useTranslation };
